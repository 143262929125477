
main {
    display: flex;
    flex-direction: row;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
  }
  main .pannel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    transition: all 0.3s ease;
  }
  main .pannel:hover {
    width: 60%;
  }
  main .pannel.left {
    background-color: #006492;
  }
  main .pannel.left a {
    text-decoration: none;
    font-size: 30px;
    color: #f8f8f8;
    padding: 30px;
    border: 3px solid transparent;
    transition: border-color 0.3s ease;
  }
  main .pannel.left a:hover {
    border-color: #f8f8f8;
  }
  main .pannel.left .half {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    transition: background-color 0.3s ease, height 0.3s ease;
  }
  main .pannel.left .half:hover {
    height: 60%;
    background-color: #E41134;
  }
  main .pannel.left .half.bottom a {
    position: relative;
  }
  main .pannel.left .half.bottom a::after {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    /* content: "challenge"; */
    font-weight: 300;
  }
  main .pannel.right {
    color: #E41134;
    background-color: #f8f8f8;
  }
  main .pannel.right:hover {
    width: 100%;
  }
  main .pannel.right p {
    margin: 0;
  }
  main .pannel.right .p404 {
    font-size: 250px;
    font-size: 20vw;
  }
  main .pannel.right .text {
    font-weight: 300;
    font-size: 50px;
    font-size: 4vw;
  }